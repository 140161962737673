import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7f04b043"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "txt"
};
const _hoisted_3 = {
  key: 0,
  class: "protocal-content-title"
};
const _hoisted_4 = ["innerHTML"];
import { getprotocalFaterSonDetail } from "@/api/index";
import { reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import cont_match_reg from "@/utils/editor.js";
import { decodeEntities } from "@/utils/tools.js";
export default {
  setup(__props) {
    const state = reactive({
      son_id: '',
      fater_id: '',
      info: {},
      content: ''
    });
    const isLucky = computed(() => window.location.href.includes('m.hi-mystery.com'));
    const route = useRoute();
    onMounted(() => {
      state.son_id = route.params.son_id;
      state.fater_id = route.params.fater_id;
      getDetail({
        fater_id: state.fater_id,
        son_id: state.son_id
      });
    });
    const getDetail = params => {
      state.fater_id = params.fater_id;
      state.son_id = params.son_id;
      getprotocalFaterSonDetail({
        fater_id: params.fater_id,
        son_id: params.son_id
      }).then(res => {
        if (res.status == 0) {
          state.info = res.data;
          document.title = res.data.son_title;
          let cont = state.info.content;
          if (cont.indexOf('&gt;') > -1) {
            cont = decodeEntities(cont);
          }
          state.content = cont_match_reg(cont, process.env.VUE_APP_ENV, 800, 'project').innerHTML;
        }
      });
    };
    const back = () => {
      window.history.go(-1);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(isLucky) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "header",
        onClick: _withModifiers(back, ["stop"])
      }, [_createElementVNode("p", _hoisted_2, _toDisplayString(_unref(state).info.son_title), 1)], 8, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(['protocal-content', {
          'lucky': _unref(isLucky)
        }, 'protocal'])
      }, [!_unref(isLucky) ? (_openBlock(), _createElementBlock("strong", _hoisted_3, _toDisplayString(_unref(state).info.son_title), 1)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "protocal-content-content",
        innerHTML: _unref(state).content
      }, null, 8, _hoisted_4)], 2)], 64);
    };
  }
};